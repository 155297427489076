import axios from './config';

export const getAIC = id => {
  return axios.get(`/natural-areas/${id}`).then(({ data }) => {
    return data.naturalArea;
  });
};

export const getAreas = params => {
  return axios.get('/natural-areas', { params }).then(({ data }) => {
    return data;
  });
};

export const updateAIC = ({ id, data }) => {
  return axios.patch(`/natural-areas/${id}`, data).then(({ data }) => {
    return data.naturalArea;
  });
};

import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';

export default function ProtectedRoute({ children, shouldBeDestination = false }) {
  const { isLoggedIn, user } = useContext(AppContext);

  if (!isLoggedIn && !user?.destination && !user?.aic) {
    return <Navigate to='/' />;
  }

  if (isLoggedIn && shouldBeDestination && !user.destination && user.aic) {
    return <Navigate to='/aic' />;
  }

  if (isLoggedIn && !shouldBeDestination && !user?.aic && user.destination) {
    return <Navigate to='/destino' />;
  }

  return children;
}

import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';

export default function AvoidIfLoggedIn({ children }) {
  const { isLoggedIn, destination } = useContext(AppContext);

  if (isLoggedIn) {
    if (destination) {
      return <Navigate to={`/destino`} />;
    }
    return <Navigate to={`/aic`} />;
  }

  return children;
}

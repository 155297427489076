import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import AvoidIfLoggedIn from '../components/common/AvoidIfLoggedin';
import ProtectedRoute from '../components/common/ProtectedRoute';
import Loader from '../components/Loaders/Loader';

/**** Destination Routes *****/ // @TODO: Add protected routes
const DestinationWrapper = lazy(() => import('../pages/Destination/Wrapper'));
const GeneralInfo = lazy(() => import('../pages/Destination/GeneralInfo'));
const DestinationPriorityProjects = lazy(() =>
  import('../pages/Destination/PriorityProject/PriorityProjects'),
);
const DestProjectForm = lazy(() => import('../pages/Destination/PriorityProject/FormWrapper'));
const DestinationAttractions = lazy(() =>
  import('../pages/Destination/Attractions/AttractionsList'),
);
const DestinationAttractionForm = lazy(() =>
  import('../pages/Destination/Attractions/FormWrapper'),
);
const DestinationFauna = lazy(() => import('../pages/Destination/Fauna'));
const DestinationFlora = lazy(() => import('../pages/Destination/Flora'));
const DestinatinationCertifications = lazy(() =>
  import('../pages/Destination/Certifications/CertificationsList'),
);
const DestinationCertificationForm = lazy(() =>
  import('../pages/Destination/Certifications/FormWrapper'),
);
const DestinationEvents = lazy(() => import('../pages/Destination/Events/EventsList'));
const DestinationEventsForm = lazy(() => import('../pages/Destination/Events/FormWrapper'));
const DestinationAwards = lazy(() => import('../pages/Destination/Awards/AwardsList'));
const DestinationAwardForm = lazy(() => import('../pages/Destination/Awards/FormWrapper'));
const DestinationAreas = lazy(() => import('../pages/Destination/Areas/AreasList'));
const DestinationOrg = lazy(() => import('../pages/Destination/Organizations/OrganizationsList'));
const DestinationEvaluation = lazy(() => import('../pages/Destination/Evaluation'));
/**** End Destination Routes *****/

/**** AIC Routes *****/ // @TODO: Add protected routes
const AicWrapper = lazy(() => import('../pages/Aic/Wrapper'));
const GeneralAicInfo = lazy(() => import('../pages/Aic/GeneralAicInfo'));
const AicPriorityProjects = lazy(() => import('../pages/Aic/PriorityProjects/PriorityProjects'));
const AicOrg = lazy(() => import('../pages/Aic/Organizations/OrganizationsList'));
const AicProjectForm = lazy(() => import('../pages/Aic/PriorityProjects/FormWrapper'));
const AicAttractions = lazy(() => import('../pages/Aic/Attractions/AttractionsList'));
const AicAttractionForm = lazy(() => import('../pages/Aic/Attractions/FormWrapper'));
const AicCertifications = lazy(() => import('../pages/Aic/Certifications/CertificationsList'));
const AicCertificationForm = lazy(() => import('../pages/Aic/Certifications/FormWrapper'));
const AicAwards = lazy(() => import('../pages/Aic/Awards/AwardsList'));
const AicAwardForm = lazy(() => import('../pages/Aic/Awards/FormWrapper'));
const AicFauna = lazy(() => import('../pages/Aic/Fauna'));
const AicFlora = lazy(() => import('../pages/Aic/Flora'));
const AicEvents = lazy(() => import('../pages/Aic/Events/EventsList'));
const AicEventsForm = lazy(() => import('../pages/Aic/Events/FormWrapper'));
// const AicStats = lazy(() => import('../pages/Aic/Stats'));
/**** End AIC Routes *****/
const Auth = lazy(() => import('../pages/Auth'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));

export default function AppRouter() {
  return (
    <Suspense fallback={<Loader cover />}>
      <Routes>
        <Route path='/recuperar-contrasena' element={<ForgotPassword />} />
        <Route path='/cambiar-contrasena' element={<ResetPassword />} />
        <Route
          path='/'
          element={
            <AvoidIfLoggedIn>
              <Auth />
            </AvoidIfLoggedIn>
          }
        />
        /**** Destination Routes *****/ // @TODO: Add protected routes
        <Route
          path='/destino'
          element={
            <ProtectedRoute shouldBeDestination>
              <DestinationWrapper />
            </ProtectedRoute>
          }
        >
          <Route
            index
            element={
              <ProtectedRoute shouldBeDestination>
                <GeneralInfo />
              </ProtectedRoute>
            }
          />

          <Route path='organizaciones'>
            <Route index element={<DestinationOrg />} />
          </Route>

          <Route path='proyectos-prioritarios'>
            <Route
              index
              element={
                <ProtectedRoute shouldBeDestination>
                  <DestinationPriorityProjects />
                </ProtectedRoute>
              }
            />
            <Route
              path='formulario'
              element={
                <ProtectedRoute shouldBeDestination>
                  <DestProjectForm />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path='atractivos'>
            <Route
              index
              element={
                <ProtectedRoute shouldBeDestination>
                  <DestinationAttractions />
                </ProtectedRoute>
              }
            />
            <Route
              path='formulario'
              element={
                <ProtectedRoute shouldBeDestination>
                  <DestinationAttractionForm />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path='certificaciones'>
            <Route
              index
              element={
                <ProtectedRoute shouldBeDestination>
                  <DestinatinationCertifications />
                </ProtectedRoute>
              }
            />
            <Route
              path='formulario'
              element={
                <ProtectedRoute shouldBeDestination>
                  <DestinationCertificationForm />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path='distinciones'>
            <Route
              index
              element={
                <ProtectedRoute shouldBeDestination>
                  <DestinationAwards />
                </ProtectedRoute>
              }
            />
            <Route
              path='formulario'
              element={
                <ProtectedRoute shouldBeDestination>
                  <DestinationAwardForm />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path='fauna'>
            <Route
              index
              element={
                <ProtectedRoute shouldBeDestination>
                  <DestinationFauna />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='flora'>
            <Route
              index
              element={
                <ProtectedRoute shouldBeDestination>
                  <DestinationFlora />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='eventos'>
            <Route
              index
              element={
                <ProtectedRoute shouldBeDestination>
                  <DestinationEvents />
                </ProtectedRoute>
              }
            />
            <Route
              path='formulario'
              element={
                <ProtectedRoute shouldBeDestination>
                  <DestinationEventsForm />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='areas-naturales'>
            <Route
              index
              element={
                <ProtectedRoute shouldBeDestination>
                  <DestinationAreas />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='evaluacion'>
            <Route
              index
              element={
                <ProtectedRoute shouldBeDestination>
                  <DestinationEvaluation />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
        /**** End Destination Routes *****/
        <Route path='/recuperar-contraseña' element={<Auth />} />
        /**** AIC Routes *****/
        <Route
          path='/aic'
          element={
            <ProtectedRoute>
              <AicWrapper />
            </ProtectedRoute>
          }
        >
          <Route
            index
            element={
              <ProtectedRoute>
                <GeneralAicInfo />
              </ProtectedRoute>
            }
          />
          {/* <Route
            path='estadisticas'
            element={
              <ProtectedRoute>
                <AicStats />
              </ProtectedRoute>
            }
          /> */}
          <Route path='proyectos-prioritarios'>
            <Route
              index
              element={
                <ProtectedRoute>
                  <AicPriorityProjects />
                </ProtectedRoute>
              }
            />
            <Route path='formulario' element={<AicProjectForm />} />
          </Route>

          <Route path='organizaciones'>
            <Route index element={<AicOrg />} />
          </Route>

          <Route path='atractivos'>
            <Route
              index
              element={
                <ProtectedRoute>
                  <AicAttractions />
                </ProtectedRoute>
              }
            />
            <Route
              path='formulario'
              element={
                <ProtectedRoute>
                  <AicAttractionForm />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path='certificaciones'>
            <Route
              index
              element={
                <ProtectedRoute>
                  <AicCertifications />
                </ProtectedRoute>
              }
            />
            <Route
              path='formulario'
              element={
                <ProtectedRoute>
                  <AicCertificationForm />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path='distinciones'>
            <Route
              index
              element={
                <ProtectedRoute>
                  <AicAwards />
                </ProtectedRoute>
              }
            />
            <Route
              path='formulario'
              element={
                <ProtectedRoute>
                  <AicAwardForm />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path='eventos'>
            <Route
              index
              element={
                <ProtectedRoute>
                  <AicEvents />
                </ProtectedRoute>
              }
            />
            <Route
              path='formulario'
              element={
                <ProtectedRoute>
                  <AicEventsForm />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path='fauna'>
            <Route
              index
              element={
                <ProtectedRoute>
                  <AicFauna />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='flora'>
            <Route
              index
              element={
                <ProtectedRoute>
                  <AicFlora />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
        /**** End Destination Routes *****/
      </Routes>
    </Suspense>
  );
}

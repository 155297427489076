import axios from './config';

export const getDestination = id => {
  return axios.get(`/destinations/${id}`).then(({ data }) => {
    return data.destination;
  });
};

export const updateDestination = ({ id, data }) => {
  return axios.patch(`/destinations/${id}`, data).then(({ data }) => {
    return data.destination;
  });
};

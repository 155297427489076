import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { locales } from '../utils/constants/common';
import { logoutRequest } from '../services/auth';
import { getDestination } from '../services/destinations';
import { getAIC } from '../services/aic';

export const AppContext = createContext({});

export default function AppContextWrapper({ children }) {
  const toast = useToast();
  const [user, setUserInfo] = useState(JSON.parse(localStorage.getItem('user')) || null);
  const [locale, setLocale] = useState(locales.es);

  const { data: destination } = useQuery(['destination'], () => getDestination(user?.destination), {
    enabled: !!user?.destination,
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 60 * 12,
  });

  const { data: aic } = useQuery(['aic'], () => getAIC(user?.aic), {
    enabled: !!user?.aic,
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 60 * 12,
  });

  const isLoggedIn = !!user;
  const navigate = useNavigate();

  const updateUserInfo = userInfo => {
    const user = {
      ...userInfo.user,
      destination: userInfo.destination?._id,
      aic: userInfo.naturalArea?._id,
    };
    localStorage.setItem('user', JSON.stringify(user));
    if (userInfo.destination) localStorage.setItem('destination', userInfo.destination._id);
    if (userInfo.naturalArea) localStorage.setItem('aic', userInfo.naturalArea._id);
    setUserInfo(user);
  };

  const logout = async path => {
    try {
      await logoutRequest();
      localStorage.clear();
      setUserInfo(null);
      navigate('/', { state: { prevPath: path } });
    } catch (error) {
      console.error(error);
    }
  };

  const checkIfTokenExpires = (error, path) => {
    if (!error) return;
    if (error.name === 'TokenExpiredError') {
      logout(path);
      toast({
        title: 'Tu sesión ha expirado',
        description: 'Debes iniciar sesión de nuevo para continuar',
        status: 'info',
        position: 'top-right',
        duration: 7000,
        isClosable: true,
      });
      return;
    }
    return;
  };

  return (
    <AppContext.Provider
      value={{
        user,
        logout,
        checkIfTokenExpires,
        locale,
        isLoggedIn,
        updateUserInfo,
        destination,
        aic,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

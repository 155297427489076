import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  styles: { global: { body: { color: '#051755' } } },
  colors: {
    brand: {
      primary: '#0bd649',
      secondary: '#051755',
      yellow: '#f7b500',
      500: '#0bd649',
    },
  },
  sizes: { 13: '3.25rem' },
  fonts: {
    heading: `'Montserrat', sans-serif`,
    body: `'Montserrat', sans-serif`,
  },
  components: {
    Button: {
      variants: {
        outlinePrimary: {
          border: '2px solid #0bd649',
          color: 'brand.primary',
          borderRadius: '3xl',
          fontSize: 'sm',
        },
        solid: {
          fontSize: 'sm',
          bg: 'brand.primary',
          color: 'white',
          borderRadius: '3xl',
          _hover: {
            bg: 'hsl(138, 90%, 40%)',
            _disabled: {
              bg: 'hsl(138, 90%, 40%)',
            },
          },
        },
        sliderDot: {
          borderRadius: 'full',
          h: '3',
          minW: 'auto',
          w: '3',
          p: '0',
        },
      },
    },
    FormLabel: {
      baseStyle: {
        position: 'relative',
        pl: '3.5',
        mb: '3',
        _before: {
          content: '""',
          left: '0px',
          width: '6px',
          height: '100%',
          maxHeight: '100%',
          backgroundColor: 'brand.primary',
          position: 'absolute',
          borderRadius: '3px',
        },
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            borderRadius: '3xl',
            _focusVisible: {
              borderColor: 'brand.secondary',
              boxShadow: '0 0 0 1px #051755',
            },
          },
        },
      },
    },
    Select: {
      variants: {
        outline: {
          field: {
            borderRadius: '3xl',
            _focusVisible: {
              borderColor: 'brand.secondary',
              boxShadow: '0 0 0 1px #051755',
            },
          },
        },
      },
    },
  },
});

export default theme;

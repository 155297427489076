import axios from './config';

export const loginRequest = (email, password) => {
  return axios.post('/auth/login/destinations', { email, password });
};

export const logoutRequest = () => {
  return axios.get('/auth/logout');
};

export const forgotPassword = data => {
  return axios.post('/auth/forgot', data).then(res => res.data);
};

export const recoverCredentials = data => {
  return axios.post('/auth/update', data).then(res => res.data);
};
